import { IBody } from '@pra-vendas-themes/interfaces/IBody';

import { WithChildren } from 'types/WithChildren';

import { useComponentsTheme } from 'hooks/useComponentsTheme';

export const Body: WithChildren<IBody> = ({ children, ...props }) => {
  const { Body: BodyTheme } = useComponentsTheme();

  return <BodyTheme {...props}>{children}</BodyTheme>;
};
