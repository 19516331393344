/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';

import Slider, { Settings } from 'react-slick';

import Link from 'next/link';

import { Body } from 'components/Body';

import { IShowcase } from 'types/IShowcase';

import { SlideItem, SliderContainer } from './styles';
import { SectionWrapper } from '../styles';

import { CustomArrow } from './CustomArrow';
import { SectionTitle } from '../SectionTitle';

interface IBrandsCarrouselProps {
  showcase?: IShowcase;
}

const BrandsCarrousel: FC<IBrandsCarrouselProps> = ({ showcase }) => {
  const activeBrands =
    !showcase?.brands || showcase.brands?.length === 0
      ? []
      : showcase.brands.filter((brand) => brand.status === 'active');

  if (!activeBrands || activeBrands.length === 0) return null;

  const settings: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: activeBrands.length >= 4 ? 4 : activeBrands.length,
    slidesToScroll: 1,
    speed: 400,
    arrows: true,
    centerMode: true,
    prevArrow: (
      <div>
        <CustomArrow variant="prev" />
      </div>
    ),
    nextArrow: (
      <div>
        <CustomArrow variant="next" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: activeBrands.length >= 3 ? 3 : activeBrands.length,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: activeBrands.length >= 2 ? 2 : activeBrands.length,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Body>
      <SectionWrapper>
        <SectionTitle title={showcase!.title} />

        <SliderContainer>
          <Slider {...settings}>
            {activeBrands.map((brand) => (
              <SlideItem key={brand._id}>
                <Link href={`/marcas/${brand._id}`}>
                  {brand.picture ? (
                    <img alt={brand.name} src={brand.picture} />
                  ) : (
                    <h1>{brand.name}</h1>
                  )}
                </Link>
              </SlideItem>
            ))}
          </Slider>
        </SliderContainer>
      </SectionWrapper>
    </Body>
  );
};

export default BrandsCarrousel;
