import { FC } from 'react';

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { Container } from './styles';

interface ICustomArrowProps {
  variant: 'next' | 'prev';
}

export const CustomArrow: FC<ICustomArrowProps> = ({ variant }) => {
  return (
    <Container variant={variant}>
      {variant === 'prev' ? <FiChevronLeft /> : <FiChevronRight />}
    </Container>
  );
};
