import { FC } from 'react';

import { IShowcase } from 'types/IShowcase';

import { Container } from './styles';

import { Showcase } from './Showcase';

interface IShowcasesProps {
  showcases: IShowcase[];
}

export const Showcases: FC<IShowcasesProps> = ({ showcases = [] }) => {
  return (
    <Container>
      {showcases?.map?.((showcase) => (
        <Showcase key={showcase._id} showcase={showcase} />
      ))}
    </Container>
  );
};
