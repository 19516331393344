/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

interface ISimpleBannerItemProps {
  hasUrl: boolean;
  itemsCount: number;
}

interface ISimpleBannersListProps {
  itemsCount: number;
}

export const SimpleBannersList = styled.ul<ISimpleBannersListProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xLarge};

  @media (max-width: 768px) {
    gap: ${({ theme }) => theme.spacing.medium};

    ${({ itemsCount }) =>
      itemsCount === 3
        ? css`
            flex-wrap: wrap;
          `
        : css`
            flex-direction: column;
          `}
  }
`;

export const SimpleBannerItem = styled.li<ISimpleBannerItemProps>`
  flex: 1;
  width: 100%;
  max-width: 100%;

  aspect-ratio: ${({ itemsCount }) =>
    itemsCount === 3
      ? '500 / 500'
      : itemsCount === 2
      ? '502 / 200'
      : '1036 / 200'};

  overflow: hidden;

  @media (max-width: 768px) {
    aspect-ratio: ${({ itemsCount }) =>
      itemsCount === 3
        ? '500 / 500'
        : itemsCount === 2
        ? '768 / 200'
        : '768 / 200'};
  }

  > a {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity ease-in 0.3s;

    pointer-events: none;

    ${({ hasUrl }) =>
      hasUrl &&
      css`
        pointer-events: initial;

        &:hover {
          opacity: 0.7;
        }
      `}

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
