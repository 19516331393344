import styled, { css } from 'styled-components';

interface IContainerProps {
  variant: 'prev' | 'next';
}

export const Container = styled.div<IContainerProps>`
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;

  ${({ variant }) =>
    variant === 'next'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transition: opacity ease-in 0.3s;

  &:hover {
    opacity: 0.6;
  }

  > svg {
    min-width: 40px;
    min-height: 40px;
    color: #999;
  }
`;
