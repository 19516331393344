/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';

import Link from 'next/link';

import { Container } from './styles';

interface ISectionTitleProps {
  title: string;
  href?: string;
}

export const SectionTitle: FC<ISectionTitleProps> = ({ title, href = '' }) => {
  return (
    <Container hasHref={!!href}>
      <div />
      <Link href={href} passHref>
        <h2>{title}</h2>
      </Link>
      <div />
    </Container>
  );
};
