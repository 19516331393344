/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';

import Link from 'next/link';

import { useMediaQuery } from 'hooks/useMediaQuery';

import { Body } from 'components/Body';

import { IShowcase } from 'types/IShowcase';

import { SimpleBannerItem, SimpleBannersList } from './styles';
import { SectionWrapper } from '../styles';

interface ISimpleBannersProps {
  showcase: IShowcase;
}

export const SimpleBanners: FC<ISimpleBannersProps> = ({ showcase }) => {
  const isScreenIn768px = useMediaQuery('(max-width: 768px)');

  const activeSimpleBanners =
    !showcase?.simpleBanners || showcase.simpleBanners.length === 0
      ? []
      : showcase.simpleBanners.filter(
          (simpleBanner) => simpleBanner.status === 'active'
        );

  if (!activeSimpleBanners || activeSimpleBanners.length === 0) return null;

  const itemsCount = activeSimpleBanners.length || 0;

  return (
    <Body>
      <SectionWrapper>
        <SimpleBannersList itemsCount={itemsCount}>
          {activeSimpleBanners.map((simpleBanner) => (
            <SimpleBannerItem
              key={simpleBanner._id}
              hasUrl={!!simpleBanner.url}
              itemsCount={itemsCount}
            >
              <Link href={simpleBanner.url || ''} passHref>
                <img
                  alt={simpleBanner.description}
                  src={
                    isScreenIn768px && !!simpleBanner.mobilePicture
                      ? simpleBanner.mobilePicture
                      : simpleBanner.desktopPicture
                  }
                />
              </Link>
            </SimpleBannerItem>
          ))}
        </SimpleBannersList>
      </SectionWrapper>
    </Body>
  );
};
