import styled, { css } from 'styled-components';

interface IContainerProps {
  hasHref: boolean;
}

export const Container = styled.header<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: ${(p) => p.theme.spacing.large};
  margin-bottom: ${(p) => p.theme.spacing.xxLarge};

  ${({ hasHref }) =>
    hasHref
      ? css`
          a {
            text-decoration: none;
            cursor: pointer;

            transition: opacity ease-in 0.3s;

            &:hover {
              opacity: 0.5;
            }
          }
        `
      : css`
          pointer-events: none;
        `}

  h2 {
    position: relative;
    text-transform: uppercase;
    font-weight: 600;
    font-size: ${(p) => p.theme.font.large};
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.colors.primary};
    text-align: center;
  }

  > div {
    height: 1px;
    background-color: ${(p) => p.theme.colors.border};
    flex: 1;
  }

  @media (max-width: 768px) {
    margin-bottom: ${(p) => p.theme.spacing.xLarge};
  }
`;
