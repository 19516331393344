import type { FC } from 'react';

import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import { IShowcase } from 'types/IShowcase';

import { getShowcases } from 'services/api/showcaseService';

import { Showcases } from 'components/Showcases';

import { getDomainByContext } from 'utils/getDomainByContext';

type THome = {
  showcases: IShowcase[];
};

const Home: FC<THome> = ({ showcases }) => {
  return <Showcases showcases={showcases} />;
};

export async function getServerSideProps(
  ctx: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<THome>> {
  const hostname = getDomainByContext(ctx);

  const showcases = await getShowcases({ hostname });

  return { props: { showcases } };
}

export default Home;
