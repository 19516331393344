import { FC, useMemo } from 'react';

import dynamic from 'next/dynamic';

import { TShowcaseType } from 'types/TShowcaseType';
import { IShowcase } from 'types/IShowcase';

import { SimpleBanners } from './SimpleBanners';
import BrandsCarrousel from './BrandsCarrousel';
import { Benenefits } from './Benefits';

const SimpleListProducts = dynamic(() => import('./SimpleListProducts'));
const RotationBanners = dynamic(() => import('./RotationBanners'));

type TShowcase = {
  showcase: IShowcase;
};

export const Showcase: FC<TShowcase> = ({ showcase }) => {
  return useMemo(() => {
    return (
      (
        {
          rotation_banners: <RotationBanners showcase={showcase} />,
          products_simple_list: <SimpleListProducts showcase={showcase} />,
          brands_carousel: <BrandsCarrousel showcase={showcase} />,
          benefits: <Benenefits showcase={showcase} />,
          simple_banners: <SimpleBanners showcase={showcase} />,
        } as Record<TShowcaseType, JSX.Element | null>
      )[showcase?.type || ''] || null
    );
  }, [showcase]);
};
