import styled from 'styled-components';

export const SectionWrapper = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: ${({ theme }) => theme.spacing.xxLarge};

  @media (max-width: 768px) {
    padding-top: ${({ theme }) => theme.spacing.xLarge};
  }
`;
