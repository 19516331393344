/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';

import Slider, { Settings } from 'react-slick';

import Link from 'next/link';

import { Body } from 'components/Body';

import { IShowcase } from 'types/IShowcase';

import { SlideItem, BenefitItem } from './styles';
import { SectionWrapper } from '../styles';

import { CustomArrow } from './CustomArrow';

interface IBenefitsProps {
  showcase?: IShowcase;
}

export const Benenefits: FC<IBenefitsProps> = ({ showcase }) => {
  const activeBenefits =
    !showcase?.benefits || showcase.benefits?.length === 0
      ? []
      : showcase.benefits.filter((benefit) => benefit.status === 'active');

  if (!activeBenefits || activeBenefits.length === 0) return null;

  const settings: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: activeBenefits.length >= 5 ? 5 : activeBenefits.length,
    slidesToScroll: 1,
    speed: 400,
    arrows: true,
    prevArrow: (
      <div>
        <CustomArrow variant="prev" />
      </div>
    ),
    nextArrow: (
      <div>
        <CustomArrow variant="next" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: activeBenefits.length >= 4 ? 4 : activeBenefits.length,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: activeBenefits.length >= 3 ? 3 : activeBenefits.length,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: activeBenefits.length >= 2 ? 2 : activeBenefits.length,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Body>
      <SectionWrapper>
        <Slider {...settings}>
          {activeBenefits.map((benefit) => (
            <SlideItem key={benefit._id}>
              <Link href={benefit.url || ''} passHref>
                <BenefitItem hasUrl={!!benefit.url}>
                  {!!benefit.picture && (
                    <img src={benefit.picture} alt={benefit.title} />
                  )}

                  <strong>{benefit.title}</strong>

                  <div
                    dangerouslySetInnerHTML={{ __html: benefit.description }}
                  />
                </BenefitItem>
              </Link>
            </SlideItem>
          ))}
        </Slider>
      </SectionWrapper>
    </Body>
  );
};
