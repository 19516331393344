import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: ${({ theme }) => theme.spacing.xxLarge};

  @media (max-width: 768px) {
    padding-bottom: ${({ theme }) => theme.spacing.xLarge};
  }
`;
