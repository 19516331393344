import styled, { css } from 'styled-components';

interface IBenefitItemProps {
  hasUrl: boolean;
}

export const SlideItem = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing.medium}`};

  @media (max-width: 768px) {
    padding: ${({ theme }) => `0 ${theme.spacing.small}`};
  }
`;

export const BenefitItem = styled.div<IBenefitItemProps>`
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme }) => `calc(200px - ${theme.spacing.medium} - 36px)`};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(p) => p.theme.spacing.small};

  transition: opacity ease-in 0.3s;

  pointer-events: none;

  ${({ hasUrl }) =>
    hasUrl &&
    css`
      pointer-events: initial;

      &:hover {
        opacity: 0.7;
      }
    `}

  > img {
    height: 36px;
    width: 36px;
    object-fit: contain;
    object-position: center;
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }

  > strong {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.textDark};
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    text-align: center;
  }

  > div {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    color: #444;

    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: center;
  }
`;
