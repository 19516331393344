import styled from 'styled-components';

export const SliderContainer = styled.div`
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
  }

  .slick-prev {
    left: -40px;
  }

  .slick-next {
    right: -40px;
  }
`;

export const SlideItem = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing.medium}`};

  @media (max-width: 768px) {
    padding: ${({ theme }) => `0 ${theme.spacing.small}`};
  }

  > a {
    margin: 0 auto;

    max-width: 248px;
    width: 100%;
    height: 80px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity ease-in 0.3s;

    &:hover {
      opacity: 0.7;
    }

    > img {
      height: 80px;
      width: auto;
      margin: 0 auto;

      object-position: center;
      object-fit: contain;
    }

    > span {
      font-size: 14px;
      text-transform: uppercase;
      line-height: 20px;

      color: ${({ theme }) => theme.colors.textDark};
      font-weight: 600;
    }
  }
`;
